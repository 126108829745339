function checkPath(location) {
  let flag = false;
  const paths = [
    "/pos",
    "/new-menu",
    "/catalogue",
    "/events",
    "/checkout",
    "/spf",
    "/property-maintenance",
  ];
  flag = paths.some((url) => location.includes(url));

  if (location.includes("transaction") || location.includes("table-header")) {
    flag = false;
  }

  return flag;
}

function checkSentryErrors(event, hint) {
  const { response } = hint.originalException;
  if (
    response &&
    response.status &&
    response.status >= 400 &&
    response.status < 500
  ) {
    return null;
  }
  return event;
}

export { checkPath, checkSentryErrors };
